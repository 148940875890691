<template>


    <div class="level_index level_indexI">



        <div class="search">
            <!-- :model="search" -->
            <el-form :inline="true">
                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="toSearch" v-model="searchtitle" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="toSearch">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="addSearch" v-if="getPurviewNew('sys:configgroup:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加配置分组
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->

                </el-form-item>
            </el-form>
        </div>



        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border ref="FanfuTable"> -->

        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id">

            <el-table-column show-overflow-tooltip type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />


            <el-table-column show-overflow-tooltip prop="id" label="ID" align="center" />
            <el-table-column show-overflow-tooltip prop="name" label="配置名称" align="center" />
            <el-table-column show-overflow-tooltip prop="title" label="配置标题" align="center" />

            <el-table-column show-overflow-tooltip prop="create_name" label="创建人" align="center" />
            <el-table-column show-overflow-tooltip prop="create_time" label="创建时间" align="center" />
            <!-- <el-table-column show-overflow-tooltip    prop="update_name" label="更新人" align="center" /> -->
            <el-table-column show-overflow-tooltip prop="update_time" label="更新时间" align="center" />




            <el-table-column show-overflow-tooltip label="操作" width="145" align="center">
                <template slot-scope="scope">
                    <div class="operate">
                        <!-- <el-button @click="view(scope.row)">
                            查看
                        </el-button> -->
                        <el-button type="primary" @click="edit(scope.row)" v-if="getPurviewNew('sys:configgroup:edit')">
                            编辑
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)" v-if="getPurviewNew('sys:configgroup:drop')">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>

        </el-table>
        <!-- </Fanfu-Table> -->

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />


        <Search ref="searchRef" v-model="search" @refresh="getList" />

    </div>

</template>

<script>
    import Search from './components/GroupSearch'

    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            Search,

        },
        data() {
            return {

                activeName: 'first',

                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],

                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0
                },

                searchtitle: ""

            }
        },

        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();

            this.getList();
        },
        methods: {

            handleClick(tab, event) {
                console.log(tab.index);
            },

            // AllTotal() {
            //     let a = list.length;
            //     for (let i = 0; i < list.length; i++) {
            //         var dic = list[i];
            //         if (dic.hasOwnProperty("children")) {
            //             a = a + dic.children.length
            //         }
            //     }
            //     return a;
            // },
            // getList(size) {
            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }
            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)
            //         setTimeout(() => {
            //             this.modifyLayout();
            //         }, 1100)
            //     })
            // },


            getList() {

                this.$http.get('/api/config', {
                    keywords: this.searchtitle,
                    page_size: this.data.size,
                    page: this.data.page

                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {
                        this.data.list = res.data.data
                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },

            view(row) {
                console.log(row)
            },

            edit(row) {
                console.log(row)

                this.$refs.searchRef.edit(row)
            },

            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },


            toSearch() {
                this.$refs.searchRef.open()
            },
            addSearch() {
                this.$refs.searchRef.open()
            },



            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getList()
            },



            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;

                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {

                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                    }

                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {

                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }

                });

            }

        },

    }
</script>


<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5BD995;
        border-color: #5BD995;
    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */
</style>