<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑配置分组':'新增配置分组'" :visible.sync="show" width="600px" class="selfInputBox selfInputBoxIV">

        <el-form v-if="formShow" ref="form" label-width="80px">

            <el-form-item label="分组名称" prop="">
                <el-input v-model="configgroupInfo.name" />
            </el-form-item>

            <el-form-item label="排序" prop="">
                <el-input v-model="configgroupInfo.sort" />
            </el-form-item>

        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                configgroupInfo: {
                    name: "",
                    sort: "",
                },

                isEdit: false

            }
        },

        mounted() {

        },

        created() {

        },

        watch: {

            show(value) {
                if (this.show) {

                } else {

                    this.isEdit = false

                    this.configgroupInfo = {
                        name: "",
                        sort: "",
                    }

                }
            },

        },

        methods: {

            open() {
                this.formShow = true
                this.show = true
            },

            edit(row) {

                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", row);

                this.isEdit = true

                this.open()

                this.configgroupInfo = {
                    ...row
                }

            },

            refresh() {

                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)

            },

            save() {

                console.log(this.configgroupInfo);

                if (this.isEdit) {

                    this.$http.put('/api/configgroup/' + this.configgroupInfo.id, this.configgroupInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }

                        if (res.code == 200) {

                            this.refresh()

                        }

                    }).catch((err) => {
                        console.log(err)
                    });


                } else {


                    this.$http.post('/api/configgroup', this.configgroupInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code == 200) {

                            this.refresh()

                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                    }).catch((err) => {
                        console.log(err)
                    });
                }

            }

        }
    }
</script>

<style>
    /* .selfInputBoxIV .el-input__inner[placeholder="请选择所属卫健委"] {
		border: 1px red solid !important;
		box-sizing: content-box !important;
	} */

    .selfInputBoxIV .el-input__suffix {
        /* border: 1px red solid !important;
    box-sizing: content-box !important; */
        right: -10px;
    }
</style>

<style scoped lang="scss">
    .trisection {
        .el-select {
            /deep/ .el-input__inner {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
            }
        }
    }
</style>